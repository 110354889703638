import React from 'react';
import './Mission.css';

const Mission = ({ missionInfo }) => {
    
  return (
    <div className='Mission-Info'>
            <div >
                <p className='Mission-text container'>{missionInfo.mission}</p>
                <p className='text-center text-light'>Made in India for Indian</p>
            </div>
    </div>
  )
}

export default Mission;