import './App.css';
import React from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import NavBar from './Components/NavBar';
import ContentView from './Components/ContentView/ContentView';



class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loaded:false,
      long:'',lat:'',
      pincode:'',
      locations:[],
      services : [],
      testimonials:[],
      banners : [],
      blogs:[],
      appInfo:{},
    }
  }
  onSuccess = async(loc)=>{
    this.setState({
      loaded:true,
      long : loc.coords.longitude,
      lat : loc.coords.latitude,
    })
    const result = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`,{
      params:{
        latlng:`${this.state.lat},${this.state.long}`,
        key:'AIzaSyBosooRQ8n10ao7l9QHAnJHyPlYExZ8UEo'
      }
    });
    const address_components = result.data.results[0].address_components
    this.setState({
      pincode : address_components[address_components.length-1].long_name,
    })

    let data = { pincode:isNaN(this.state.pincode) ? '751030' :this.state.pincode};

    try {
      const response = await axios.post('http://3.15.227.148/vkareu/customerapi/areabypincode',data);
      console.log('response',response);
      this.setState({locations:response.data.data})
    } catch (error) {
      console.log("error",error)
    }
    
  }
  onError = (error)=>{
    this.setState({
      loaded:true,
      error
    })
    console.log(error)
    swal({
      title:'Error',
      text: `${error.message}`,
      closeOnClickOutside: false,
      icon: "warning",
    });
  }
  async componentDidMount() {
    if (!('geolocation' in navigator)) {
      this.onError({
        code:0,
        message:'Location not supported'
      })
    }
    navigator.geolocation.getCurrentPosition(this.onSuccess,this.onError);
    try {
        const services = await axios.get('http://3.15.227.148/vkareu/customerapi/serviceslist');
        const appHome = await axios.get('http://3.15.227.148/vkareu/customerapi/apphome');
        console.log(appHome);
        console.log('service',services);
        this.setState({
          services:services.data.data,
          testimonials:appHome.data.data.testimonials,
          banners:appHome.data.data.banners,
          blogs:appHome.data.data.blog,
          appInfo:appHome.data.data.appinformation,
        })
    } catch (error) {
        console.log(error);
    }
    
  }
  render(){
    return (
      <div className="">
        <NavBar  locations={this.state.locations} />
        <ContentView 
            services={this.state.services} 
            testimonials={this.state.testimonials} 
            banners={this.state.banners} 
            blogs={this.state.blogs} 
            appInfo={this.state.appInfo} 
        />
      </div>
    );
  }
}

export default App;
