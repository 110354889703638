import React from 'react';
import './Testimonial.css';

const Testimonials = ({ testimonials }) =>{
    return (
        <div className='testimonials'>
            <h4>Testimonials</h4>
            <div className="row row-cols-1 row-cols-md-2 g-4 ">
                
                {
                    testimonials.map( (testimonial) => (
                        <Testimonial key={testimonial.id} testimonial={testimonial} /> 
                    )) 
                }
            </div>

        </div>
    )
}

function Testimonial({testimonial})
{
    return (
            <div className="col testimonial-container">
                <div className="card h-100 testimonial-card">
                    <div className="card-header d-flex testimonial-header">
                        <img
                            src={`http://3.15.227.148/vkareu/${testimonial.image}`}
                            alt={testimonial.name}
                            loading="lazy"
                            className='testimonial-image'
                        />
                        <div>
                            <h5 className="card-title testimonial-name">{testimonial.name}</h5>
                            <h5 className="card-title testimonial-age">{testimonial.age} Years</h5>
                        </div>
                    </div>
                    <div className="card-header">
                        <h5 className="card-title testimonial-title">{testimonial.title}</h5>
                    </div>
                    <div className="card-body">
                        <p className="card-text testimonial-description">{testimonial.description}</p>
                    </div>
                    <div className="card-footer text-center">
                        <small className="testimonial-rating">Rated {testimonial.ratings} star for previous booking</small>
                    </div>
                </div>
            </div>
   
    )
}
export default Testimonials;