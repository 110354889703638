import React,{ useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import './PopUp.css';

const PopUp = ({ trigger, locations, setLocation }) => {

    const [showModal, setShow] = useState(true);
    const [chooseLoc, setChooseLoc] = useState({area_id:101,area:'All Bhubaneswar'});

    const handleShow = () => setShow(true);

    const handleClose = () => {
        setLocation(chooseLoc);
        setShow(false);
    };
    
    const handleClick = (area) =>{
        setChooseLoc(area);
        setLocation(area);
        setShow(false);
    }
  return (
      trigger ? (
        <div>
            <MyLocationIcon onClick={handleShow} className='location-icon' />
            
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Choose Your Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-body'>
                        {
                            locations.map((loc) => (
                                <Button 
                                    key={loc.area_id} 
                                    className="btn btn-light m-2" 
                                    onClick={() => {handleClick(loc)}}
                                >
                                    {loc.area}
                                </Button>
                            ))
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className='btn btn-sm' onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      ) : ''
  )
}

export default PopUp