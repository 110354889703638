import React from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import './AppInfo.css';

const AppInfo = ({ appInfo }) => {
    
  return (
    <div className='App-Info'>
        {
            <div>
                <p className='appinfo-mission container'>Our Community of Partners and patients drive us to create technologies for better and affordable healthcare.</p>
                <div className='appInfo-icons'>
                    <div>
                        <PersonOutlineIcon className='user-icon' />
                        <p>Our Users</p>
                        <h6>{appInfo.users}</h6>
                    </div>
                    <div>
                        <HandshakeIcon className='user-icon' />
                        <p>Our Partners</p>
                        <h6>{appInfo.patients}</h6>
                    </div>
                    <div>
                        <LocationCityIcon className='user-icon' />
                        <p>Cities Covered</p>
                        <h6>{appInfo.city}</h6>
                    </div>
                </div>
            </div>
        }
    </div>
  )
}

export default AppInfo;