import React from 'react';
import { Card } from 'react-bootstrap';
import ReadMore from '../../ReadMore/ReadMore';

import './Blogs.css';


const Blogs = ({ blogs }) =>{
    
  return (
      <div className='blog-container'>
          <h4>Blogs from VKareU</h4>
            
            <div className="row row-cols-1 row-cols-md-2 g-4 ">
                {
                    blogs.map( (blog) => (
                        <div className="col" key={blog.id}>
                            <div className="card h-100 blog-card">
                                <div className="card-header d-flex blog-header">
                                    <Card.Img variant="top" className='blog-img' src={`http://3.15.227.148/vkareu/${blog.image}`} alt={blog.name} />
                                </div>
                                <div className="card-header">
                                    <h5 className="card-title blog-title">{blog.title}</h5>
                                </div>
                                <div className="card-body">
                                    <ReadMore>
                                        {blog.description}
                                    </ReadMore>
                                </div>
                                <div className="card-footer text-end">
                                    <button className='btn btn-sm '>Share</button>
                                </div>
                            </div>
                        </div>
                    )) 
                }
            </div>
            
      </div>
  )
}

export default Blogs;