import React from 'react';
import {Card } from 'react-bootstrap';
import './Services.css';

const Services = ({ services }) => {
    return (
        !services ? '': 
        <div className='service-container'>
            <h4>Services</h4>

            <div className="row row-cols-1 row-cols-md-6 g-4 ">
                {
                    services.map((service) =>(
                        <div className="col" key={service.servicelist_id}>
                            <div className="card h-100 service-card">
                                <div className="card-body">
                                    <Card.Img variant="top" className='service-img'  src={service.icon} alt={service.name} />
                                    <h5 className="card-title">{service.name}</h5>
                                    <p className="card-text">{service.description}</p>
                                    <div className="text-center service-btn">
                                        <button className='btn btn-sm text-light'>Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) 
                }
            </div>
        </div>
        

    )
}

export default Services;