import React from 'react';
import Services from '../Services/Services';
import './ContentView.css';
import Testimonials from '../AppHome/Testimonial/Testimonials';
import Banners from '../AppHome/Banners/Banners';
import Blogs from '../AppHome/Blogs/Blogs';
import AppInfo from '../AppHome/AppInfo/AppInfo';
import Mission from '../AppHome/AppInfo/Mission';

function ContentView({ services, testimonials, banners, blogs, appInfo }) {
  return (
    <div className=' main'>
        <Services services={services} />
        <Banners banners={banners} />
        <Testimonials testimonials={testimonials} banners={banners} />
        <AppInfo appInfo={appInfo} />
        <Blogs blogs={blogs} />
        <Mission missionInfo={appInfo} />
    </div>
  )
}

export default ContentView;