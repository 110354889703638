import React, { Component } from 'react';
import './NavBar.css';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import LocationBar from './LocationBar';


class NavBar extends Component{
    render(){
        return(
            <div>
                <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light">
                    <div className="container-fluid">
                        <a className="navbar-brand text-light" href="#"><img src="https://vkareu.com/wp-content/uploads/2021/11/logo-b-250x300.png" width="50px" height="50px" /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                            <form className="d-flex">
                                <LocationBar locations={this.props.locations} />
                                {/* <button className="btn btn-outline-success" type="submit">Search</button> */}
                            </form>
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                <a className="nav-link active  fw-bold" aria-current="page" href="#"><HomeIcon className='nav-icons' /> Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link   fw-bold" href="#"><AccountCircleIcon className='nav-icons' /> Profile</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link   fw-bold" href="#"><InsertInvitationIcon className='nav-icons' /> My Bookings</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link   fw-bold" href="#"><AccountBalanceWalletIcon className='nav-icons' /> Refer</a>
                                </li>
                            </ul>
                            
                            <div>
                                <button className='btn login-btn  btn-sm btn-outline-primary'>Login / SignUp</button>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}
export default NavBar;