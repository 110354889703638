import React from 'react';
import './Banner.css';

export default function Banners({banners}) {
return (
	<div className='banner-container'>
        <div className='container'>
            <h4 className='mx-5'>In the spotlight</h4>
            <p className='mx-5'>Explore deals, offers, health updates &amp; more</p>
            <div className='banners container-fluid'>
                
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        {
                            banners.map((banner,index) =>(
                                <button type="button" key={banner.id} data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={index === 0 ? "active" : ''} aria-current="true" aria-label={`Slide ${index}`}></button>
                            ))
                        }
                    </div>
                    <div className="carousel-inner">
                        {
                            banners.map((banner , index) =>(
                                <div className={index === 0 ? "carousel-item active" : 'carousel-item'} data-bs-interval="2000" key={banner.id}>
                                    <img src={banner.banner} alt={banner.service_name} className="d-block  banner-img "/>
                                </div>
                            ))
                        }
                    </div>
                    <button className="carousel-control-prev text-danger" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
	</div>
);
}
