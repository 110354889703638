import React, { useState } from 'react';
import PopUp from './PopUp';


import './LocationBar.css';


const LocationBar = (props)=> {
  const [location, setLocation ] = useState({});
  // const [trigger, setTrigger ] = useState(true);

  
  return (
    <div className='LocationBar d-flex'>
        <div className='loc-para col-md-6'>
          {!location ? '' : `${location.area} ` }
        </div>
        <PopUp trigger={true} locations={props.locations} setLocation={setLocation}/>
        
    </div>
  )
}
  export default LocationBar;
